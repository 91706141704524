import React, { useState, useEffect } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import { eventBus } from "utils/eventbus";
import FormField from "layouts/admin/accountants/components/FormField";

import { useAPICall } from "utils/useapicall";
import { GetClassesList, EditClass } from "services/userservices";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { EditClassesSchema } from "../components/schemas/editclassesvalidation";

function EditClasses({ onClose, classesId, businessId }) {
  const [btnDisable, setBtnDisable] = useState(false);
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [className, setClassName] = useState("");
  const [classList, setClassList] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [trackPayment, setTrackPayment] = useState(false);
  const [
    { isLoading: isEditClassLoading, isError: isEditClassError, response: EditClassResponse },
    EditClassListAPICall,
  ] = useAPICall(EditClass, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesList, { onLoad: false });

  useEffect(async () => {
    if (classList.length != 0) {
      const Data = classList.find((obj) => {
        return obj._id === classesId;
      });
      setClassName(Data?.name !== "---" ? Data.name : "");
      setShowDetails(true);
      setTimeout(() => {
        setLoading(false);
      }, 500);
    }

    if (classesId) {
      setTimeout(() => {
        setShowDetails(true);
        setLoading(false);
      }, 2000);
    }
  }, [classList, classesId]);

  useEffect(async () => {
    setLoading(true);
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      setLoading(false);
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      setClassList(ClassesResponse.data.data.records);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isEditClassLoading) {
      return () => null;
    }
    if (isEditClassError) {
      setLoading(false);
      setBtnDisable(false);
      return () => null;
    }
    if (EditClassResponse && EditClassResponse.data.isSuccess === true) {
      setLoading(false);
      setBtnDisable(false);
      onClose();
      eventBus.$emit("reloadClassDetailsList", EditClassResponse);
    }
  }, [EditClassResponse]);

  let initialValues = {
    classes: className,
  };

  const handleSubmit = async (values) => {
    setBtnDisable(true);
    await EditClassListAPICall({
      payload: [
        {
          name: values.classes,
          classesId: `${classesId}`,
        },
      ],
    });
  };

  return (
    <>
      {showDetails && (
        <>
          <MDBox mt={0}>
            <Grid spacing={3}>
              <Grid item xs={12}>
                <Formik
                  initialValues={initialValues}
                  validationSchema={EditClassesSchema}
                  onSubmit={handleSubmit}
                >
                  {({ values, errors, touched, isSubmitting }) => (
                    <Form autoComplete="off">
                      <MDBox p={1}>
                        <MDBox>
                          <MDBox mt={1.625} ml={"30%"}>
                            <Grid container spacing={3}>
                              <Grid item xs={12} sm={9}>
                                <FormField type="text" label="Class Name *" name="classes" />
                              </Grid>
                              {/* <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Mobile Number"
                                                                name="phone"
                                                            />
                                                        </Grid> */}
                            </Grid>
                            {/* <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="email"
                                                                label="Email"
                                                                name="email"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Address"
                                                                name="address"
                                                            />
                                                        </Grid>
                                                    </Grid> */}
                            {/* <Grid container spacing={3}>
                                                        <Grid item xs={12} sm={6}>
                                                            <FormField
                                                                type="text"
                                                                label="Net Terms"
                                                                name="net"
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} sm={6}>

                                                        </Grid>
                                                    </Grid> */}
                          </MDBox>
                          <MDBox mt={2} width="100%" display="flex" justifyContent="end">
                            <MDButton
                              disabled={btnDisable}
                              variant="gradient"
                              color="light"
                              onClick={onClose}
                            >
                              Cancel
                            </MDButton>
                            <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                              <MDButton
                                disabled={btnDisable}
                                type="submit"
                                variant="gradient"
                                color="info"
                              >
                                {btnDisable ? "updating..." : "update"}
                              </MDButton>
                            </MDBox>
                          </MDBox>
                        </MDBox>
                      </MDBox>
                    </Form>
                  )}
                </Formik>
              </Grid>
            </Grid>
          </MDBox>
        </>
      )}
      <MDBox>
        {loading && (
          <CircularProgress
            size={50}
            sx={{
              color: green[500],
              position: "absolute",
              top: "50%",
              left: "50%",
              marginTop: "-12px",
              marginLeft: "-12px",
            }}
          />
        )}
      </MDBox>
    </>
  );
}

export default EditClasses;
