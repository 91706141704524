import { Formik, Form, Field, ErrorMessage } from "formik";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from "@mui/material/colors";
import { addCategoryschema } from "./components/Itemschema/addCategoryschema";
import FormField from "layouts/admin/accountants/components/FormField";
import {
  EditItem,
  GetCustomerAllList,
  GetClassesAllList,
  GetCategoryAllList,
  GetProductsAllList,
} from "../../../../services/userservices";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useAPICall } from "utils/useapicall";
import Checkbox from "@mui/material/Checkbox";
import { eventBus } from "utils/eventbus";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import AddCustomerDialog from "./addnewcustomer";
import AddClassDialog from "./addnewclass";
import AddNewCategoryDialog from "./addnewcategory";
import { makeStyles } from "@material-ui/core/styles";
import CurrencyFieldText from "../components/CurrencyField-Text";
import MDTypography from "components/MDTypography";
import NumberFieldText from "../components/NumberField";
import { useMaterialUIController } from "context";

const useStyles = makeStyles({
  option: {
    "&:first-child": {
      color: "#0000ff",
    },
    "&:hover:first-child": {
      color: "#0000ff",
      backgroundColor: "none !important",
    },
  },
});

function EditCategoryItemDialog({
  noCategory,
  itemListResponse,
  invoiceId,
  editItemId,
  businessId,
  onClose,
  currencySymbol,
  showClasses,
}) {
  const styles = useStyles();
  const [isDisable, setBtnDisable] = useState(false);
  const [unit, setunit] = useState("");
  const [catError, setCaterror] = useState(noCategory);
  const [openAddNewCustomer, setOpenAddNewCustomer] = useState(false);
  const [openAddNewClass, setOpenAddNewClass] = useState(false);
  const [priceErrorMsg, setPriceErrorMsg] = useState("");
  const [openAddNewCategory, setOpenAddNewCategory] = useState(false);
  const [sku, setSku] = useState("");
  const [itemItem, setItemitem] = useState("");
  const [quantity, setQuantity] = useState(0);
  const [total, setTotal] = useState(0);
  const [price, setPrice] = useState(0);
  const [quantityErrorMsg, setQuantityErrorMsg] = useState("");
  const [TotalPrice, setTotalPrice] = useState(0);
  const [noPrices, setNoPrices] = useState(false);
  const [showerrorqty, setShowerrorqty] = useState(false);
  const [showerror, setShowerror] = useState(false);
  const [noQuantity, setNoQuantity] = useState(false);
  const [errQuantity, seterrQuantity] = useState(noQuantity);
  const [quantityerror, setQuantityrror] = useState(false);
  const [id, setId] = useState("");
  const [priceerror, setpriceerror] = useState(false);
  const [itemDetails, setItemDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [adjustInvoice, setAdjustInvoice] = useState(false);
  const [classIdIds, setClassIdId] = useState("");
  const [customerIds, setCustomerIdId] = useState("");
  const [categoryIds, setCategoryIds] = useState("");
  const [errUnitPrice, seterrUnitPrice] = useState(false);
  const [description, setDescription] = useState("");
  const [unitPrice, setUnitPrice] = useState("");
  const [classesList, setClassesList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [categoryName, setCategoryName] = useState("");
  const [className, setClassName] = useState("");
  const [customerName, setCustomerName] = useState("");
  const [askClients, setAskClients] = useState(false);
  const [categoryerrormsg, setCategoryerrormsg] = useState("");
  const [categoryerror, setCategoryerror] = useState(false);
  const [errorButtonDis, setErrorButtonDis] = useState(false);
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;
  const [{ isError, isLoading, response }, setAPICall] = useAPICall(EditItem, { onLoad: false });
  const [
    { isLoading: isCustomerLoading, isError: isCustomerError, response: CustomerResponse },
    CustomerListAPICall,
  ] = useAPICall(GetCustomerAllList, { onLoad: false });
  const [
    { isLoading: isClassesLoading, isError: isClassesError, response: ClassesResponse },
    ClassesListAPICall,
  ] = useAPICall(GetClassesAllList, { onLoad: false });
  const [
    { isLoading: isCategoryLoading, isError: isCategoryError, response: CategoryResponse },
    CategoryListAPICall,
  ] = useAPICall(GetCategoryAllList, { onLoad: false });
  const [
    { isLoading: isProductsLoading, isError: isProductsError, response: ProductsResponse },
    ProductsListAPICall,
  ] = useAPICall(GetProductsAllList, { onLoad: false });

  const AddCategory = { name: "+ Add Category" };
  const NewCategoryList = [AddCategory, ...categoryList];
  const AddCustomer = { name: "+ Add Customer" };
  const NewCustomerList = [AddCustomer, ...customerList];
  const AddClass = { name: "+ Add Class" };
  const NewClassesList = [AddClass, ...classesList];

  useEffect(() => {
    let prices = typeof price == "string" ? price.replaceAll(",", "") : price;
    let quantitys = typeof quantity == "string" ? quantity.replaceAll(",", "") : quantity;
    if (Number(prices) <= 0.0) {
      setErrorButtonDis(true);
    }
    if (showerror === true) {
      // if (prices == 0) {
      //   setPriceErrorMsg("");
      //   setpriceerror(false);
      // } else if (prices?.length === 0) {
      //   setPriceErrorMsg("Price is required");
      //   setpriceerror(true);
      // } else {
      //   setPriceErrorMsg("");
      //   setpriceerror(false);
      // }
      //  else if (prices.length === 0 || Number(prices) === 0) {
      //     setPriceErrorMsg('Price Must be more than 0');
      //     setpriceerror(true);
      // }
    }
    if (prices?.length === 0 || quantitys?.length === 0 || Number(quantitys) === 0) {
      setErrorButtonDis(true);
    } else {
      setErrorButtonDis(false);
    }
    if (showerrorqty === true) {
      if (!quantitys) {
        setQuantityErrorMsg("Quantity is required");
        setQuantityrror(true);
      } else if (quantitys.length === 0 || Number(quantitys) === 0) {
        setQuantityErrorMsg("Quantity Must be more than 0");
        setQuantityrror(true);
      } else {
        setQuantityErrorMsg("");
        setQuantityrror(false);
      }
    }

    const timeOutId = setTimeout(
      async () => setTotalPrice(Number(prices) * Number(quantitys)),
      500
    );
    return () => clearTimeout(timeOutId);
  }, [price, quantity]);

  useEffect(() => {
    if (price) {
      if (
        price.length === 0 ||
        (Number(price) === 0 && quantity.length === 0) ||
        Number(quantity) === 0
      ) {
        setErrorButtonDis(true);
      } else {
        setErrorButtonDis(false);
      }
    }
  }, []);

  useEffect(async () => {
    setCaterror(noCategory);
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    await ProductsListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }, []);

  useEffect(() => {
    if (isCustomerLoading) {
      return () => null;
    }
    if (isCustomerError) {
      return () => null;
    }
    if (CustomerResponse && CustomerResponse.data.isSuccess === true) {
      const data = CustomerResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          customerId: prop._id,
        };
      });
      setCustomerList(data);
    }
  }, [CustomerResponse]);

  useEffect(() => {
    if (isClassesLoading) {
      return () => null;
    }
    if (isClassesError) {
      return () => null;
    }
    if (ClassesResponse && ClassesResponse.data.isSuccess === true) {
      const data = ClassesResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          classId: prop._id,
        };
      });
      setClassesList(data);
    }
  }, [ClassesResponse]);

  useEffect(() => {
    if (isCategoryLoading) {
      return () => null;
    }
    if (isCategoryError) {
      return () => null;
    }
    if (CategoryResponse && CategoryResponse.data.isSuccess === true) {
      const data = CategoryResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          categoryId: prop._id,
        };
      });
      setCategoryList(data);
    }
  }, [CategoryResponse]);

  useEffect(() => {
    if (isProductsLoading) {
      return () => null;
    }
    if (isProductsError) {
      return () => null;
    }
    if (ProductsResponse && ProductsResponse.data.isSuccess === true) {
      const data = ProductsResponse.data.data.records.map((prop, key) => {
        return {
          name: prop.name,
          productId: prop._id,
          accountingId: prop.accountingId,
          description: prop.description,
          unitPrice: prop.unitPrice,
        };
      });
      setProductsList(data);
    }
  }, [ProductsResponse]);

  useEffect(async () => {
    if (isLoading) {
      return () => null;
    }
    if (isError) {
      setBtnDisable(false);
      return () => null;
    }
    if (response && response.data.isSuccess === true) {
      eventBus.$emit("closeEditItemPopup", response);
      setBtnDisable(false);
    }
  }, [response]);

  useEffect(() => {
    setLoading(true);
    const result = itemListResponse?.data?.data?.records.map((prop, key) => {
      {
        if (editItemId === prop._id) {
          return {
            id: prop._id,
            item: prop.description,
            qty: prop.quantity,
            unit: prop.unitOfMeasure,
            price: prop.unitPrice,
            total: prop.total,
            sku: prop.sku,
            askClients: prop.askClient,
            category: prop.category ? prop.category.name : "",
            categoryId: prop.category ? prop.category._id : "",
            class: prop.class ? prop.class.name : "",
            classId: prop.class ? prop.class._id : "",
            customer: prop.customer ? prop.customer.name : "",
            customerId: prop.customer ? prop.customer._id : "",
          };
        } else {
          return {
            id: prop.id,
            item: prop.description,
            qty: prop.quantity,
            unit: prop.unitOfMeasure,
            price: prop.unitPrice,
            total: prop.total,
            sku: prop.sku,
            askClients: prop.askClient,
            category: prop.category ? prop.category.name : "",
            categoryId: prop.category ? prop.category._id : "",
            class: prop.class ? prop.class.name : "",
            classId: prop.class ? prop.class._id : "",
            customer: prop.customer ? prop.customer.name : "",
            customerId: prop.customer ? prop.customer._id : "",
          };
        }
      }
    });
    setTimeout(() => {
      const results = result.find((item) => item.id === editItemId);
      const Str = results?.price;
      const newStr =
        typeof Str === typeof String() ? (Str.includes(",") ? Str.replace(/,/g, "") : Str) : Str;
      setItemDetails(results);
      setItemitem(results?.item);
      setQuantity(Number(results?.qty));
      setunit(results?.unit);
      setPrice(newStr);
      setTotal(results?.total);
      setId(results?.id);
      setSku(results?.sku);
      setAskClients(results?.askClients);
      setCategoryName(results?.category);
      setCustomerName(results?.customer);
      setClassName(results?.class);
      setCategoryIds(results?.categoryId);
      setClassIdId(results?.classId);
      setCustomerIdId(results?.customerId);
      setLoading(false);
    }, 1000);
  }, [itemListResponse]);

  const initialValues = {
    item: itemItem,
    sku: sku,
    customer: "",
    qty: quantity,
    unit: unit,
    total: total,
    price: price,
    category: "",
  };
  const handleSubmit = (values) => {
    // if (price?.length === 0) {
    //   setPriceErrorMsg("Price is required");
    //   setpriceerror(true);
    // } else {
    //   setPriceErrorMsg("");
    //   setpriceerror(false);
    // }

    if (quantity.length === 0 || Number(quantity) === 0) {
      setQuantityErrorMsg("Quantity is required");
      setQuantityrror(true);
    } else {
      setQuantityErrorMsg("");
      setQuantityrror(false);
    }

    if (categoryIds) {
      setCategoryerrormsg("");
      setCategoryerror(false);
    } else {
      setCategoryerrormsg("Category name required");
      setCategoryerror(true);
    }
    setBtnDisable(true);
    setAPICall({
      payload: [
        {
          itemType: "AccountBasedExpenseLineDetail",
          amount: Number(TotalPrice),
          unitPrice: Number(price),
          quantity: Number(quantity),
          discount: 0,
          unitOfMeasure: values?.unit,
          description: values.item,
          InvoiceId: `${invoiceId}`,
          classId: classIdIds ? classIdIds : null,
          customerId: customerIds ? customerIds : null,
          categoryId: categoryIds ? categoryIds : null,
          ItemId: `${editItemId}`,
          askClient: askClients,
          adjustDocument: adjustInvoice,
        },
      ],
    });
  };

  const handleChange = () => {
    setAdjustInvoice(!adjustInvoice);
  };

  const handleChangeCategory = (event, newValue) => {
    if (newValue == "+ Add Category") {
      setOpenAddNewCategory(true);
    } else {
      setCaterror(false);
      setCategoryName(newValue);
      if (newValue) {
        setCategoryerrormsg("");
        setCategoryerror(false);
      } else {
        setCategoryerrormsg("Category name required");
        setCategoryerror(true);
      }
      const found = categoryList.find((obj) => {
        return obj.name === newValue;
      });
      if (found) {
        setCategoryIds(found.categoryId);
        setDescription(found.description);
        setUnitPrice(found.unitPrice);
      }
    }
  };

  const handleChangeClass = (event, newValue) => {
    if (newValue == "+ Add Class") {
      setOpenAddNewClass(true);
    } else {
      setClassName(newValue);
      const found1 = classesList.find((obj) => {
        return obj.name === newValue;
      });
      if (found1) {
        setClassIdId(found1.classId);
      }
    }
  };

  const handleChangeCustomer = (event, newValue) => {
    if (newValue == "+ Add Customer") {
      setOpenAddNewCustomer(true);
    } else {
      setCustomerName(newValue);
      const found2 = customerList.find((obj) => {
        return obj.name === newValue;
      });
      if (found2) {
        setCustomerIdId(found2.customerId);
      }
    }
  };

  eventBus.$on("AddnewCategoryComplete", (data) => AddnewCategoryComplete(data));
  async function AddnewCategoryComplete(data) {
    await CategoryListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    setCategoryName(data?.account?.name);
    setCategoryIds(data?.account?._id);
  }

  eventBus.$on("AddnewCustomerComplete", (data) => AddnewCustomerComplete(data));
  async function AddnewCustomerComplete(data) {
    await CustomerListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
    setCustomerName(data?.customer?.name);
    setCustomerIdId(data?.customer?._id);
  }

  eventBus.$on("AddnewClassComplete", (data) => AddnewClassComplete(data));
  function AddnewClassComplete(data) {
    setClassName(data?.class?.name);
    setClassIdId(data?.class?._id);
    ClassesListAPICall({
      payload: [{ businessId: `${businessId}` }],
    });
  }

  return (
    <>
      {loading ? (
        <MDBox>
          <Grid sx={{ mt: 40 }}>
            <Grid item xs={12} lg={12}>
              <CircularProgress
                size={50}
                sx={{
                  color: green[500],
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  marginTop: "-12px",
                  marginLeft: "-12px",
                }}
              />
            </Grid>
          </Grid>
        </MDBox>
      ) : (
        <MDBox width="auto">
          <Grid item xs={12} lg={12}>
            <Formik
              // enableReinitialize
              initialValues={initialValues}
              validationSchema={addCategoryschema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form autoComplete="off">
                  <MDBox p={2} pt={2}>
                    <MDBox>
                      <MDBox>
                        <Grid container spacing={3} sx={{ mb: 2 }}>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              sx={
                                darkMode
                                  ? {
                                      "& .MuiInput-underline": {
                                        borderBottom: "2px solid #ffffff",
                                      },
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                      "& .MuiInput-root .MuiInput-input": {
                                        WebkitTextFillColor: "white",
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                        {
                                          color: "white !important",
                                        },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                        {
                                          color: "white !important",
                                        },
                                    }
                                  : {
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                    }
                              }
                              classes={{
                                option: styles.option,
                              }}
                              options={NewCategoryList}
                              value={{ name: categoryName }}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, newInputValue) => {
                                handleChangeCategory(event, newInputValue);
                              }}
                              id="controllable-states-demo"
                              disableCloseOnSelect={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Category *"
                                  variant="standard"
                                  helperText={categoryerrormsg}
                                  error={catError || categoryerror}
                                  InputLabelProps={{
                                    style: { color: darkMode ? "#ffffff" : "#000000" },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <Autocomplete
                              sx={
                                darkMode
                                  ? {
                                      "& .MuiInput-underline": {
                                        borderBottom: "2px solid #ffffff",
                                      },
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                      "& .MuiInput-root .MuiInput-input": {
                                        WebkitTextFillColor: "white",
                                      },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                        {
                                          color: "white !important",
                                        },
                                      "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                        {
                                          color: "white !important",
                                        },
                                    }
                                  : {
                                      "& .MuiInputBase-input.Mui-disabled": {
                                        WebkitTextFillColor: "black",
                                      },
                                    }
                              }
                              classes={{
                                option: styles.option,
                              }}
                              options={NewCustomerList}
                              value={{ name: customerName }}
                              getOptionLabel={(option) => option.name}
                              onInputChange={(event, newInputValue) => {
                                handleChangeCustomer(event, newInputValue);
                              }}
                              id="controllable-states-demo"
                              disableCloseOnSelect={false}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Customer"
                                  variant="standard"
                                  InputLabelProps={{
                                    style: { color: darkMode ? "#ffffff" : "#000000" },
                                  }}
                                />
                              )}
                            />
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          {showClasses ? (
                            <>
                              <Grid item xs={12} sm={6}>
                                <Autocomplete
                                  sx={
                                    darkMode
                                      ? {
                                          "& .MuiInput-underline": {
                                            borderBottom: "2px solid #ffffff",
                                          },
                                          "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "black",
                                          },
                                          "& .MuiInput-root .MuiInput-input": {
                                            WebkitTextFillColor: "white",
                                          },
                                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-popupIndicator":
                                            {
                                              color: "white !important",
                                            },
                                          "& .MuiIconButton-root.MuiIconButton-sizeMedium.MuiAutocomplete-clearIndicator":
                                            {
                                              color: "white !important",
                                            },
                                        }
                                      : {
                                          "& .MuiInputBase-input.Mui-disabled": {
                                            WebkitTextFillColor: "black",
                                          },
                                        }
                                  }
                                  classes={{
                                    option: styles.option,
                                  }}
                                  options={NewClassesList}
                                  value={{ name: className }}
                                  getOptionLabel={(option) => option.name}
                                  onInputChange={(event, newInputValue) => {
                                    handleChangeClass(event, newInputValue);
                                  }}
                                  id="controllable-states-demo"
                                  disableCloseOnSelect={false}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label="Class"
                                      variant="standard"
                                      InputLabelProps={{
                                        style: { color: darkMode ? "#ffffff" : "#000000" },
                                      }}
                                    />
                                  )}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6} mt={-1}>
                                <FormField type="text" label="Description" name="item" />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid item xs={12} sm={12}>
                                <FormField type="text" label="Description" name="item" />
                              </Grid>
                            </>
                          )}
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6} mt={-0.5}>
                            <FormField type="text" label="Unit" name="unit" />
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Price"
                              name="price"
                              value={price}
                              fullWidth
                              priceMismatch={errUnitPrice}
                              // error={errUnitPrice || priceerror}
                              onValueChange={(event) => {
                                setPrice(event.value);
                                setShowerror(true);
                              }}
                            />
                            {/* <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {priceErrorMsg}
                              </MDTypography>
                            </MDBox> */}
                          </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: 1 }}>
                          <Grid item xs={12} sm={6}>
                            <NumberFieldText
                              label="Quantity"
                              name="qty"
                              priceMismatch={errQuantity}
                              // error={errQuantity || quantityerror}
                              value={quantity}
                              fullWidth
                              onValueChange={(event) => {
                                setQuantity(event.value);
                                setShowerrorqty(true);
                              }}
                            />
                            {/* <MDBox mt={0.75}>
                              <MDTypography
                                component="div"
                                variant="caption"
                                color="error"
                                fontWeight="regular"
                              >
                                {quantityErrorMsg}
                              </MDTypography>
                            </MDBox> */}
                          </Grid>
                          <Grid item xs={12} sm={6}>
                            <CurrencyFieldText
                              label="Total Price"
                              name="TotalPrice"
                              disabled
                              fullWidth
                              value={TotalPrice}
                              error={noPrices}
                            />
                          </Grid>
                        </Grid>
                        <Grid item xs={12}>
                          <MDBox>
                            <Field
                              as={FormControlLabel}
                              type="checkbox"
                              name="checkbox"
                              control={<Checkbox />}
                              label={<span style={{color: darkMode ? "#ffffff" : "#000"}}>Adjust Total Price</span>}
                              checked={adjustInvoice}
                              onChange={handleChange}
                              sx={{ mt: 1.5 }}
                            />
                          </MDBox>
                        </Grid>
                      </MDBox>
                      <MDBox mb={-2} mt={5} display="flex" justifyContent="end">
                        <MDButton
                          disabled={isDisable}
                          variant="gradient"
                          color="light"
                          onClick={onClose}
                        >
                          Cancel
                        </MDButton>
                        <MDBox ml={{ xs: 0, sm: 1 }} mt={{ xs: 1, sm: 0 }}>
                          <MDButton
                            // disabled={isDisable || errorButtonDis}
                            type="submit"
                            variant="gradient"
                            color="info"
                          >
                            { isDisable ? "Submitting..." : "Submit"}
                          </MDButton>
                        </MDBox>
                      </MDBox>
                    </MDBox>
                  </MDBox>
                </Form>
              )}
            </Formik>
          </Grid>
          <MDBox>
            <Dialog
              open={openAddNewCustomer}
              onClose={() => setOpenAddNewCustomer(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Customer
              </DialogTitle>
              <DialogContent>
                <AddCustomerDialog
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewCustomer(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewClass}
              onClose={() => setOpenAddNewClass(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Class
              </DialogTitle>
              <DialogContent>
                <AddClassDialog
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewClass(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
          <MDBox>
            <Dialog
              open={openAddNewCategory}
              onClose={() => setOpenAddNewCategory(false)}
              aria-labelledby="alert-dialog-title"
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "570px",
                  },
                },
              }}
            >
              {" "}
              <DialogTitle
                sx={{
                  justifyContent: "center",
                  display: "flex",
                }}
              >
                Add Category
              </DialogTitle>
              <DialogContent>
                <AddNewCategoryDialog
                  Edit={true}
                  invoiceId={invoiceId}
                  businessId={businessId}
                  onClose={() => setOpenAddNewCategory(false)}
                />
              </DialogContent>
            </Dialog>
          </MDBox>
        </MDBox>
      )}
    </>
  );
}

export default EditCategoryItemDialog;
